import React from 'react';
import Routes from './Routes';
import './global.css';

function App() {
  return (
      <Routes/>
    );
}

export default App;